<template>
  <div
    v-if="totalItems"
    class="custom-pagination"
  >
    <b-button
      :disabled="currentPage === 1"
      class="btn btn-link"
      @click="goToPage(1)"
    >
      ‹‹
    </b-button>
    <b-button
      :disabled="currentPage === 1"
      class="btn btn-link"
      @click="goToPrevPage"
    >
      ‹
    </b-button>

    <b-form-select
      v-model="currentPage"
      :options="pageList"
      class="custom-pagination-page-dropdown"
      @input="goToPage"
    />

    <b-button
      :disabled="currentPage === totalPages"
      class="btn btn-link"
      @click="goToNextPage"
    >
      ›
    </b-button>
    <b-button
      :disabled="currentPage === totalPages"
      class="btn btn-link"
      @click="goToPage(totalPages)"
    >
      ››
    </b-button>
    Item Per Page:
    <b-form-select
      v-model="perPage"
      :options="[10, 20, 50, 100, 150, 200].map(value => ({ value, text: `${value}`}))"
      class="custom-pagination-page-dropdown"
      @change="changeItemPerPage"
    />
  </div>
</template>

<script>
import {
  BButton,
  BFormSelect,
} from 'bootstrap-vue'

export default {
  name: 'CustomPagination',
  components: {
    BButton,
    BFormSelect,
  },
  props: {
    currentPage: {
      type: [String, Number],
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage)
    },
    pageList() {
      const pages = []
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push({ value: i, text: `Page ${i}` })
      }
      return pages
    },
  },
  watch: {
    totalItems() {
      if (this.currentPage > this.totalPages) {
        this.goToPage(this.totalPages)
      }
    },
  },
  methods: {
    changeItemPerPage(itemPerPage) {
      this.$emit('change-item-per-page', itemPerPage)
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.$emit('page-changed', page)
      }
    },
    goToPrevPage() {
      if (this.currentPage > 1) {
        this.goToPage(this.currentPage - 1)
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.goToPage(this.currentPage + 1)
      }
    },
  },
}
</script>

<style scoped>
.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.custom-pagination-page-dropdown {
  width: 150px;
}
</style>
