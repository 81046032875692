<template>
  <div>
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="4"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_from')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.startDate"
              :max="filter.endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_from')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.timeFrom"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="4"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_to')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.endDate"
              :min="filter.startDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm mb-1-md">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_to')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.endTime"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="4"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.account"
              autocomplete="off"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm mb-1-md-remove">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('shoe_id')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.shoeId"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData"
            v-text="$t('search')"
          />
          <b-button
            variant="success float-right"
            class="mr-1"
            @click="exportData"
            v-text="$t('export')"
          />
        </b-col>
      </b-row>
    </b-card>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <div class="card">
        <div class="card-body">
          <div class="vgt-wrap">
            <div class="vgt-inner-wrap">
              <div class="vgt-responsive game-history-table">
                <table class="vgt-table bordered">
                  <thead>
                    <tr>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('partner_name')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('account')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('begin_time')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('vip_room')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('shoe_id')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('bet_type')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('amount')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('player')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('banker')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('result')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('win_lose_amount')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('settle_status')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('end_time')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('rolling')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('operation')" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                    >
                      <!-- Partner Name -->
                      <td class="vgt-left-align">
                        <span v-text="item.partner_name" />
                      </td>
                      <!-- Account -->
                      <td class="vgt-left-align">
                        <a
                          href="#"
                          class="align-middle ml-50"
                          @click="filterByAccount(item.account)"
                          v-text="item.account"
                        />
                      </td>
                      <!-- Begin Time -->
                      <td class="vgt-left-align">
                        <span v-text="item.begin_time" />
                      </td>
                      <!-- VIP Room -->
                      <td class="vgt-left-align">
                        <span v-text="item.vip_room" />
                      </td>
                      <!-- Shoe ID -->
                      <td class="vgt-left-align">
                        <span class="game-table-id">{{ item.game_table_id }}</span>
                        <span class="game-shoe-id">{{ item.game_shoe_id }}</span>
                        <span class="game-round-id">{{ item.game_round_id }}</span>
                      </td>
                      <!-- Bet Type -->
                      <td class="vgt-left-align">
                        <span
                          :class="`game-history-${item.bet_type}-type`"
                          v-text="item.bet_type.toUpperCase()"
                        />
                      </td>
                      <!-- Amount -->
                      <td class="vgt-left-align">
                        <span
                          class="align-middle ml-50"
                          v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(item.amount)"
                        />
                      </td>
                      <!-- Player -->
                      <td
                        class="vgt-left-align"
                        :class="{
                          'winning-card': item.card_result === 'player'
                        }"
                      >
                        <div
                          v-if="item.player_game_card.length > 0"
                          class="text-center card-result-container"
                          :class="(item.card_result !== 'player') ? 'lose-card' : ''"
                        >
                          <span
                            v-for="(card, index) in item.player_game_card"
                            :key="index"
                            class="card-display"
                          >
                            <img
                              :src="card.icon"
                            >
                            <span v-text="card.display" />
                          </span>
                        </div>
                      </td>
                      <!-- Banker -->
                      <td
                        class="vgt-left-align"
                        :class="{
                          'winning-card': item.card_result === 'banker'
                        }"
                      >
                        <div
                          v-if="item.banker_game_card.length > 0"
                          class="text-center"
                        >
                          <span
                            v-for="(card, index) in item.banker_game_card"
                            :key="index"
                            class="card-display"
                          >
                            <img
                              :src="card.icon"
                            >
                            <span v-text="card.display" />
                          </span>
                        </div>
                      </td>
                      <!-- Result -->
                      <td class="vgt-center-align">
                        <span
                          v-text="item.result"
                        />
                        <br>
                        <span
                          :class="`result-${item.win_lose}`"
                          v-text="item.win_lose.toUpperCase()"
                        />
                      </td>
                      <!-- Win Lose Amount -->
                      <td class="vgt-left-align">
                        <span
                          v-if="item.win_lose == 'lose'"
                          class="card-result-banker"
                          v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(item.win_lose_amount)"
                        />
                        <span
                          v-else-if="item.win_lose == 'tie'"
                          class="card-result-tie"
                          v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(item.win_lose_amount)"
                        />
                        <span
                          v-else
                          v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(item.win_lose_amount)"
                        />
                      </td>
                      <!-- Settle Status -->
                      <td class="vgt-left-align">
                        <span
                          :class="`settle-status-${item.settle_status.toLowerCase()}`"
                          v-text="item.settle_status"
                        />
                      </td>
                      <!-- End Time -->
                      <td class="vgt-left-align">
                        <span v-text="item.end_time" />
                      </td>
                      <!-- Rolling -->
                      <td class="vgt-left-align">
                        <span
                          v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(item.rolling)"
                        />
                      </td>
                      <!-- Action -->
                      <td class="vgt-left-align">
                        <!-- Todo: Add v-if="data.item.can_settle === true" -->
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          @click="showSettle(item)"
                          v-text="$t('settle')"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <custom-pagination
            v-model="filter.page"
            :total-items="totalItems"
            :per-page="perPage"
            :current-page="filter.page"
            @page-changed="loadData"
            @change-item-per-page="changeItemPerPage"
          />
        </div>
      </div>
    </b-overlay>
    <!-- Settle Modal -->
    <b-modal
      ref="settle-modal"
      size="lg"
      :title="$t('settle')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="settle-modal"
        @submit.stop.prevent="settleHandleSubmit"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('player_cards')"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <span
                v-for="(item, index) in settleFormData.cards.player"
                :key="index"
                class="card-display clickable"
                @click="showCardSelection('player', index)"
              >
                <img
                  v-if="item.card !== null"
                  :src="cardTypes[item.card.substring(0, 1)]"
                >
                <span v-else>?</span>
                <span
                  v-if="item.card !== null"
                  v-text="(item.card.substring(1) === 'X') ? '10' : item.card.substring(1)"
                />
              </span>
              <b
                class="ml-1"
                v-text="$t('player')"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('banker_cards')"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <span
                v-for="(item, index) in settleFormData.cards.banker"
                :key="index"
                class="card-display clickable"
                @click="showCardSelection('banker', index)"
              >
                <img
                  v-if="item.card !== null"
                  :src="cardTypes[item.card.substring(0, 1)]"
                >
                <span v-else>?</span>
                <span
                  v-if="item.card !== null"
                  v-text="(item.card.substring(1) === 'X') ? '10' : item.card.substring(1)"
                />
              </span>
              <b
                class="ml-1"
                v-text="$t('banker')"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="settleHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="settleHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>
    <!-- Card Modal -->
    <b-modal
      ref="card-modal"
      size="md"
      :title="$t('select_card')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="card-modal"
        @submit.stop.prevent="cardSelectionHandleSubmit"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('card_type')"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <span
                v-for="(item, index) in cardTypes"
                :key="index"
                class="card-display clickable"
                :class="(selectedCard.data.type !== null && selectedCard.data.type === index) ? 'selected' : ''"
                @click="selectedCard.data.type = index"
              >
                <img :src="item">
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('card_value')"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <span
                v-for="(item, index) in cardValues"
                :key="index"
                class="card-display clickable"
                :disabled="selectedCard.data.type === null"
                :class="(selectedCard.data.cardResultLabel !== null && selectedCard.data.cardResultLabel === item.cardResultLabel) ? 'selected' : ''"
                @click="selectCard(item)"
              >
                <span v-text="item.label" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('result')"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <span
                class="card-display"
              >
                <img
                  v-if="selectedCard.data.type !== null"
                  :src="cardTypes[selectedCard.data.type]"
                >
                <span
                  v-if="selectedCard.data.cardResultLabel !== null"
                  v-text="selectedCard.data.cardResultLabel"
                />
                <span v-else>?</span>
              </span>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="cardSelectionHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="cardSelectionHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>
  </div>
</template>

<script>

import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BFormDatepicker,
  BFormTimepicker,
  BButton,
} from 'bootstrap-vue'

import Game from '@/models/Game'

import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import CustomPagination from '@/components/table/CustomPagination.vue'
import { EventBus } from '../../../eventbus'

export default {
  name: 'ReportGameHistory',
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormTimepicker,
    BButton,
    CustomPagination,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      agentId: null,
      items: [],
      loading: true,
      report: {
        total_bet: 0,
        total_rolling: 0,
        total_win_loss: 0,
      },
      perPage: 10,
      totalItems: 0,
      orderBy: {
        field: typeof this.$route.query.orderByField !== 'undefined' ? this.$route.query.orderByField : 'begin_time',
        sort: typeof this.$route.query.orderBySort !== 'undefined' ? this.$route.query.orderBySort : 'DESC',
      },
      filter: {
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        shoeId: typeof this.$route.query.shoeId !== 'undefined' ? this.$route.query.shoeId : '',
        settleStatus: typeof this.$route.query.settleStatus !== 'undefined' ? this.$route.query.settleStatus : '',
        startDate: typeof this.$route.query.startDate !== 'undefined' ? this.$route.query.startDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        endDate: typeof this.$route.query.endDate !== 'undefined' ? this.$route.query.endDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        startTime: typeof this.$route.query.startTime !== 'undefined' ? this.$route.query.startTime : '00:00:00',
        endTime: typeof this.$route.query.endTime !== 'undefined' ? this.$route.query.endTime : '23:59:59',
        includeDownline: 'true',
      },
      currentFilter: {
        page: 1,
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        shoeId: typeof this.$route.query.shoeId !== 'undefined' ? this.$route.query.shoeId : '',
        settleStatus: typeof this.$route.query.settleStatus !== 'undefined' ? this.$route.query.settleStatus : '',
        startDate: typeof this.$route.query.startDate !== 'undefined' ? this.$route.query.startDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        endDate: typeof this.$route.query.endDate !== 'undefined' ? this.$route.query.endDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        startTime: typeof this.$route.query.startTime !== 'undefined' ? this.$route.query.startTime : '00:00:00',
        endTime: typeof this.$route.query.endTime !== 'undefined' ? this.$route.query.endTime : '23:59:59',
        includeDownline: 'true',
      },
      cardTypes: [],
      cardValues: [],
      settleFormData: {
        loading: false,
        betId: null,
        cards: {
          player: [],
          banker: [],
        },
        selectedCard: {},
      },
      selectedCard: {
        elem: null,
        index: null,
        data: {
          type: null,
          value: null,
          cardResultLabel: null,
        },
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async exportData(a) {
      a.preventDefault()
      try {
        await Game.downloadGameHistoryList({
          ...this.currentFilter,
          agentId: this.agentId,
          orderByField: this.orderBy.field,
          orderBySort: this.orderBy.sort,
        })
      } catch (e) {
        this.$alertHelper.error()
      }
    },
    async changeItemPerPage(itemPerPage) {
      this.perPage = itemPerPage
      this.loadData(1)
    },
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      this.filter.perPage = this.perPage
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      searchParams.orderByField = this.orderBy.field
      searchParams.orderBySort = this.orderBy.sort
      searchParams.auditUnsettled = true

      this.$router.replace({ query: searchParams })
      const response = await Game.histories(searchParams)
      if (response.data.status) {
        this.items = response.data.game.data
        this.totalItems = response.data.game.pagination.total
        this.perPage = response.data.game.pagination.per_page
        this.agentId = response.data.meta.agent.id
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: this.$t(response.data.message),
          },
        }, {
          position: 'top-center',
        })
      }
      EventBus.$emit('getMyUpdatedUserData')
      this.loading = false
    },
    filterByAccount(account) {
      this.filter.account = account
      this.loadData()
    },
    resetSettleFormData() {
      this.settleFormData = {
        loading: false,
        betId: null,
        cards: {
          player: [
            {
              card: null,
              value: null,
            },
            {
              card: null,
              value: null,
            },
            {
              card: null,
              value: null,
            },
          ],
          banker: [
            {
              card: null,
              value: null,
            },
            {
              card: null,
              value: null,
            },
            {
              card: null,
              value: null,
            },
          ],
        },
      }
    },
    showSettle(data) {
      this.resetSettleFormData()
      this.cardTypes = data.card_types
      this.cardValues = data.card_values
      this.settleFormData.betId = data.id
      this.$refs['settle-modal'].show()
    },
    settleHandleCancel() {
      this.$refs['settle-modal'].hide()
    },
    showCardSelection(card, index) {
      this.resetCardSelectionFormData()
      this.selectedCard.elem = card
      this.selectedCard.index = index
      this.$refs['card-modal'].show()
    },
    resetCardSelectionFormData() {
      this.selectedCard = {
        elem: null,
        index: null,
        data: {
          type: null,
          value: null,
          cardResultLabel: null,
        },
      }
    },
    cardSelectionHandleCancel() {
      this.resetCardSelectionFormData()
      this.$refs['card-modal'].hide()
    },
    cardSelectionHandleSubmit() {
      let card = null
      let value = null
      if ((this.selectedCard.data.type !== null && this.selectedCard.data.type !== '') && (this.selectedCard.data.value !== null && this.selectedCard.data.value !== '')) {
        card = `${this.selectedCard.data.type}${this.selectedCard.data.cardResultLabel}`
        value = this.selectedCard.data.value
      }
      if (this.selectedCard.elem === 'player') {
        this.settleFormData.cards.player[this.selectedCard.index].card = card
        this.settleFormData.cards.player[this.selectedCard.index].value = value
      } else if (this.selectedCard.elem === 'banker') {
        this.settleFormData.cards.banker[this.selectedCard.index].card = card
        this.settleFormData.cards.banker[this.selectedCard.index].value = value
      }
      this.$refs['card-modal'].hide()
    },
    selectCard(data) {
      if (this.selectedCard.data.type !== null) {
        this.selectedCard.data.value = data.value
        this.selectedCard.data.cardResultLabel = data.cardResultLabel
      }
    },
    async settleHandleSubmit() {
      try {
        if (this.settleFormData.loading === false) {
          this.settleFormData.loading = true
          const response = await Game.settle(this.settleFormData)
          this.settleFormData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
            this.$refs['settle-modal'].hide()
            this.resetSettleFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
          }
        }
      } catch (e) {
        this.settleFormData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
  },
}
</script>
